import { capitalize } from "lodash";
import { useState }   from "react";

import {
	uploadingStates,
	useGetCancelUploadFunction,
	useGetInProgressVideoDetails,
	useGetIsUploadingVideo,
	useGetUploadSizeInfoAtom,
	useGetUploadThumbnailUrl,
	useGetUploadTypeAtom,
	useSetInProgressVideoDetails,
	useSetIsUploadingVideo,
	useSetVideoModal
} from "_common/hooks/uploadVideo";
import toast            from "_common/toast";
import {
	abortDirectUpload,
	abortMultipartUpload,
	completeMultipartUpload,
	directUploadComplete
} from "_services/api/upload";

import Presentation from "./presentation";

export const getIsResumable = ( { inProgressVideoDetails, isUploadingVideo } ) => {
	return inProgressVideoDetails?.status === "INITIATED" && isUploadingVideo === uploadingStates.UPLOAD_PENDING;
};

const component = props => {
	const { progress, hideThumbnailFromAvatarMenu, withDivider = false } = props;

	const cancelUploadFunction      = useGetCancelUploadFunction ();
	const uploadSizeInfo            = useGetUploadSizeInfoAtom ();
	const thumbnailUrl              = useGetUploadThumbnailUrl ();
	const uploadType                = useGetUploadTypeAtom ();
	const inProgressVideoDetails    = useGetInProgressVideoDetails ();
	const isUploadingVideo          = useGetIsUploadingVideo ();
	const setVideoModal             = useSetVideoModal ();
	const setInProgressVideoDetails = useSetInProgressVideoDetails ();
	const setIsUploadingVideo       = useSetIsUploadingVideo ();

	const [ isResuming, setIsResuming ] = useState ( false );

	const isResumeable = getIsResumable ( { inProgressVideoDetails, isUploadingVideo } );

	let loaded = uploadSizeInfo?.progressEventLoaded ? Math.floor ( uploadSizeInfo.progressEventLoaded / 1000000 ) : 0;
	let total  = uploadSizeInfo?.progressEventTotal ? Math.floor ( uploadSizeInfo?.progressEventTotal / 1000000 ) : 0;

	const onCancelUpload = async () => {
		try {
			if ( !inProgressVideoDetails )
				return;

			if ( cancelUploadFunction?.fn )
				cancelUploadFunction.fn ( `${ uploadType } uploading has been cancelled.` );

			if ( inProgressVideoDetails.type === "direct" ) {
				const type = inProgressVideoDetails.duration <= 60 ? "reel" : "video";

				await abortDirectUpload ( { type, feedId: inProgressVideoDetails.feedId } );
			} else {
				await abortMultipartUpload ( { type: inProgressVideoDetails?.type, feedId: inProgressVideoDetails?.feedId } );
			}

			setInProgressVideoDetails ( prev => ( { ...prev, status: "ABORTED" } ) );
			setIsUploadingVideo ( uploadingStates.UPLOAD_CANCELLED );
			toast.info ( `${ capitalize ( uploadType ) } upload cancelled` );
		} catch ( error ) {
			toast.error ( error?.displayMsg || "Something went wrong." );
			setIsUploadingVideo ( uploadingStates.UPLOAD_PENDING );
		}
	};

	const onResumeUpload = async () => {
		try {
			if ( isResuming )
				return;

			setIsResuming ( true );
			// for multipart upload
			if ( inProgressVideoDetails.type === "multipart" ) {
				const verifiedChunksCount = inProgressVideoDetails.parts.filter ( item => item.verified ).length;
				const totalChunksCount    = inProgressVideoDetails.parts.length;

				if ( verifiedChunksCount === totalChunksCount ) {
					await completeMultipartUpload ( { type: "multipart", feedId: inProgressVideoDetails.feedId } );
					setInProgressVideoDetails ( prev => ( { ...prev, status: "COMPLETED" } ) );
					toast.info ( "Video Uploaded, Processing Started." );
				} else {
					setVideoModal ( true );
				}

			} else if ( inProgressVideoDetails.verified ) { // for direct upload
				const type = inProgressVideoDetails.duration < 60 ? "reel" : "video";

				await directUploadComplete ( { type, feedId: inProgressVideoDetails.feedId } );
				setInProgressVideoDetails ( prev => ( { ...prev, status: "COMPLETED" } ) );
				toast.info ( `${ capitalize ( type ) } Uploaded Successfully` );

			} else {
				setVideoModal ( true );
			}
			setIsResuming ( false );

		} catch ( error ) {
			toast.error ( error?.userMessage || "Something went wrong" );
		}
	};

	return (
		<Presentation
			hideThumbnailFromAvatarMenu = { hideThumbnailFromAvatarMenu }
			isResumeable                = { isResumeable }
			isResuming                  = { isResuming }
			isUploadingVideo            = { isUploadingVideo }
			loaded                      = { loaded }
			onCancelUpload              = { onCancelUpload }
			onResumeUpload              = { onResumeUpload }
			progress                    = { progress }
			thumbnailUrl                = { thumbnailUrl }
			total                       = { total }
			uploadType                  = { uploadType }
			withDivider                 = { withDivider }
		/>
	);
};

export default component;
