import React from "react";

import Presentation from "./presentation";

const component = ( {
	coinsCount = "",
	containerClassName = ""
} ) => {
	return (
		<Presentation
			coinsCount         = { coinsCount }
			containerClassName = { containerClassName }
		/>
	);
};

export default component;