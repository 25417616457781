import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {

	return {
		videoUploadStatus: {
			padding: "1.5rem"
			// width   : "17vw"
		},
		resumeStatus: {
			padding       : "1.5rem",
			paddingBottom : "0"
			// width   : "25vw"
		},
		videoUploadStatusButtons: {
			display        : "flex",
			justifyContent : "flex-end"
		},

		clearBtn: {
			cursor: "pointer"
		},
		retryBtn: {
			color  : theme.other.paletteNew.primaryDarkBlue,
			cursor : "pointer"
		},

		crossIcon: {
			height      : "2.4rem",
			width       : "2.4rem",
			marginRight : "0.8rem"
		},
		titleContainer: {
			display      : "flex",
			alignItems   : "flex-start",
			marginBottom : "0.8rem"
		},
		image: {
			borderRadius: "1rem"
		}
	};
} );
