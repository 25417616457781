import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props, u ) => {

	return {
		navContainer: {
			position   : "sticky",
			overflowY  : "auto",
			width      : "100%",
			top        : "70px",
			height     : "calc(100vh - 70px)",
			paddingTop : props?.isStudioPage ? "0" : "2rem"
		},
		navWrapper: {
			display        : "flex",
			flexDirection  : "column",
			alignItems     : "center",
			justifyContent : "center"
		},
		policyStyles: {
			display    : "flex",
			alignItems : "center"
		},
		policyContainer: {
			display    : "flex",
			alignItems : "center",
			position   : "absolute",
			bottom     : "0rem",
			left       : "1rem"
			// [u.smallerThan ( "md" )] : {
			// display: "none !important"
			// }
		},
		fixNavContainer: {
			overflowY : "auto",
			top       : "70px"
		},
		navContainerNarrow: {
			position  : "sticky",
			overflowY : "auto",
			// top       : "70px"
			top       : "85px"
		},
		svg: {
			// width: "32px"
			width: "3rem"
			// transform : "scale(0.8)"
		},
		svgNoWidth: {
			// transform: "scale(0.8)",
			width: "3rem"
		},
		goLiveContainer: {
			width   : "100%",
			padding : "1rem 0"
		},
		createBtn: {
			fontSize : "1.4rem",
			padding  : "1.0rem 3rem"
		},
		goLiveButton: {
			fontSize                 : "1.4rem",
			[u.smallerThan ( "sm" )] : {
				display: "none !important"
			}
		},
		goLiveButtonDrawer: {
			color         : "#fff",
			width         : "100%",
			background    : "linear-gradient(to left, #439aff, #126bd5)",
			padding       : "10px",
			borderRadius  : "5px",
			textTransform : "none !important"

		},
		goLiveIcon: {
			cursor         : "pointer",
			display        : "flex",
			justifyContent : "center"
		},
		menuContainer: {
			margin       : "0.625rem 0",
			// padding      : " 1.5625rem",
			// width        : "100%",
			borderRadius : "10px"
		},
		dropdown: {
			background   : theme.other.colors.background.primary,
			color        : theme.other.colors.text.primary,
			borderRadius : "1rem",
			overflow     : "hidden",
			padding      : "0",
			minWidth     : "175px !important",
			// maxWidth     : "175px !important",

			"& .MuiMenu-list": {
				width: "auto !important"
			}
		},
		menuIcons: {
			width       : "30px",
			marginRight : ".625rem"
		},
		menuItemContainer: {
			display        : "flex",
			justifyContent : "space-evenly",
			alignItems     : "center",
			fontSize       : "1.5rem",
			padding        : "5px"
		},
		liveText: {
			fontSize: "1.5rem"
		},
		endLiveBtn: {
			cursor: "pointer"
		},
		ellipse: {
			backgroundColor : "#757575",
			borderRadius    : "50%",
			display         : "inline-block",
			height          : "3px",
			width           : "3px"
		},
		// new styles
		container: {
			padding : "0px 1.25em",
			width   : "fit-content",
			border  : props?.isStudioPage ? "none" : "1px solid #e7e7e7",
			height  : "calc(100vh - 70px)"
		},
		icon: {
			width     : "30px",
			display   : "block",
			marginTop : "30px",
			cursor    : "pointer"
		},
		anchorStyles: {
			color: "rgba(0,0,0,0.85)"
		},
		createButtonText: {
			fontSize: "1.4rem"
		},
		loaderMenuItem      : { padding: "0 5px" },
		rectangularSekleton : { width: "70%" },
		seperator           : {
			margin          : "10px 0",
			height          : "1px",
			backgroundColor : theme.other.colors.border.primary,
			width           : "100%"
		},
		title: {
			flex        : "1",
			paddingLeft : "0.25rem"
		},
		menuItem: {
			borderRadius: "0"
		}

	};
} );
