import { Modal } from "@mantine/core";

import { PrimaryButton, TertiaryButton } from "_components/atoms/button";
import Icon                              from "_components/atoms/icon";
import Typography                        from "_components/atoms/typography";

import useStyles from "./styles";

const UploadInProgressModal = props => {
	const {
		isOpen,
		onClose,
		onCancel,
		loading
	} = props;

	const { classes } = useStyles ();

	return (
		<Modal
			centered
			classNames      = { { content: classes.content, body: classes.body, root: classes.root } }
			onClose         = { onClose }
			opened          = { isOpen }
			size            = { 600 }
			withCloseButton = { false }
		>
			<Icon
				className       = { classes.closeIcon }
				name            = { "crossIcon" }
				onClick         = { onClose }
			/>

			<div className = { classes.mainBody }>

				<Typography
					className = { classes.title }
					title     = "Upload already in progress"
				/>

				<Typography
					className = { classes.subTitle }
					color     = { "secondary" }
					title     = "Upload is already in progress from other device. You can either cancel that upload or try after some time"
				/>

				<div className = { classes.buttonDiv }>
					<PrimaryButton
						fullWidth
						className = { classes.reuplaodBtn }
						onClick   = { onClose }
						title     = { "Okay" }
					/>

					<TertiaryButton
						fullWidth
						loading             = { loading }
						onClick             = { onCancel }
						title               = { "Cancel upload from other device" }
					/>
				</div>

			</div>
		</Modal>
	);
};

export default UploadInProgressModal;
