import { Waypoint } from "react-waypoint";

import Loader from "_components/atoms/loader";
import Render from "_components/atoms/render";

const component = ( { isLoading, isDepleted, fetchMore } ) => {
	return (
		<>
			<Render condition = { isLoading }>
				<Loader size = "xl" />
			</Render>

			<Render condition = { !isDepleted && !isLoading }>
				<Waypoint
					onEnter = { () => {
						fetchMore ();
					} }
				/>

				<div className = "topSpacing"></div>
			</Render>
		</>
	);
};

export default component;
