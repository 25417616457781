import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomWithReset }                  from "jotai/utils";
import { focusAtom }                      from "jotai-optics";

export const defaultLanguage = {
	"localeKey"       : "hi",
	"name"            : "हिंदी",
	"image"           : "https://s3-ap-southeast-1.amazonaws.com/rooter-images/languages/hindi.png",
	"priority"        : 1,
	"localeInEnglish" : "Hindi"
};

export const initialData = {
	rtmpDetails: {
		rtmpUrl      : "",
		streamKey    : "",
		cdnUrl       : null,
		streamName   : null,
		ivsChannelId : null
	},
	streamDetails: {
		title              : "",
		game               : {},
		tagsList           : [],
		streamLanguage     : defaultLanguage,
		streamDescription  : "",
		androidPackageName : "",
		gameName           : ""
	},
	thumbnailDetails: {
		thumbnailUrl        : null,
		isUploadedThumbnail : false
	},
	fanRank: {
		fanRankAmount : 0,
		fanRankStatus : false
	},
	donationGoal: {
		donationGoalAmount : 0,
		donationGoalStatus : false
	},
	sameDayFanLeaderBoard : false,
	recordStream          : 1,
	isFollowerOnlyChat    : false,
	isDonorOnlyChat       : false,
	audienceVisibility    : undefined,
	newFollowersGained    : 0,
	fromLastSession       : false
};

export const dataAtom = atom ( initialData );

export const useGetStreamParams = () => {
	return useAtomValue ( dataAtom );
};

export const useSetStreamParams = () => {
	return useSetAtom ( dataAtom );
};

const rtmpDetails        = focusAtom ( dataAtom, optic => optic.prop ( "rtmpDetails" ) );
const streamDetails      = focusAtom ( dataAtom, optic => optic.prop ( "streamDetails" ) );
const fanRank            = focusAtom ( dataAtom, optic => optic.prop ( "fanRank" ) );
const donationGoal       = focusAtom ( dataAtom, optic => optic.prop ( "donationGoal" ) );
const isFollowerOnlyChat = focusAtom ( dataAtom, optic => optic.prop ( "isFollowerOnlyChat" ) );
const isDonorOnlyChat    = focusAtom ( dataAtom, optic => optic.prop ( "isDonorOnlyChat" ) );
const newFollowersGained = focusAtom ( dataAtom, optic => optic.prop ( "newFollowersGained" ) );

const isStreamEnded              = atomWithReset ( false );
const isOpenChatManagement       = atomWithReset ( false );
const isOpenStreamSummaryModal   = atomWithReset ( false );
const showStreamPreviewVideo     = atomWithReset ( false );
const isStreamPreviewing         = atomWithReset ( false );
const streamExperienceRating     = atomWithReset ( 0 );
const streamSummary              = atomWithReset ( {} );
const concurrentViewersChartData = atomWithReset ( [] );
const moderatorsList             = atomWithReset ( [] );
const irlGameData                = atomWithReset ( "" );
const pollsOpenAtom              = atom ( false );
const pollsCountAtom             = atom ( 0 );
const pollsEndedDataAtom         = atom ( {} );
const pollsStartedDataAtom       = atom ( {} );
const streamHealthCheckModalAtom = atom ( false );

export const categoryTab = atom ( "game" );
export const isOpenEditSettingsModal = atom ( false );

export const useGetStreamHealthCheckModal = () => useAtomValue ( streamHealthCheckModalAtom );
export const useSetStreamHealthCheckModal = () => useSetAtom ( streamHealthCheckModalAtom );

export const useGetNewFollowerGained = () => {
	return useAtomValue ( newFollowersGained );
};

export const useSetNewFollowerGained = () => {
	return useSetAtom ( newFollowersGained );
};

// liveParams revamp
export const useGetRtmpDetails = () => {
	return useAtomValue ( rtmpDetails );
};

export const useSetRtmpDetails = () => {
	return useSetAtom ( rtmpDetails );
};

export const useGetStreamDetails = () => {
	return useAtomValue ( streamDetails );
};

export const useSetStreamDetails = () => {
	return useSetAtom ( streamDetails );
};

export const useGetFanRank = () => {
	return useAtomValue ( fanRank );
};

export const useSetFanRank = () => {
	return useSetAtom ( fanRank );
};

export const useGetDonationGoal = () => {
	return useAtomValue ( donationGoal );
};

export const useSetDonationGoal = () => {
	return useSetAtom ( donationGoal );
};

export const useGetIsFollowerOnlyChat = () => {
	return useAtomValue ( isFollowerOnlyChat );
};

export const useSetIsFollowerOnlyChat = () => {
	return useSetAtom ( isFollowerOnlyChat );
};

export const useGetIsDonorOnlyChat = () => {
	return useAtomValue ( isDonorOnlyChat );
};

export const useSetIsDonorOnlyChat = () => {
	return useSetAtom ( isDonorOnlyChat );
};

export const useSetIsStreamEnded = () => {
	return useSetAtom ( isStreamEnded );
};

export const useGetIsStreamEnded = () => {
	return useAtomValue ( isStreamEnded );
};

export const useSetOpenChatManagement = () => {
	return useSetAtom ( isOpenChatManagement );
};

export const useGetOpenChatManagement = () => {
	return useAtomValue ( isOpenChatManagement );
};

export const useSetToggleStreamSummaryModal = () => {
	return useSetAtom ( isOpenStreamSummaryModal );
};

export const useGetToggleStreamSummaryModal = () => {
	return useAtomValue ( isOpenStreamSummaryModal );
};

export const useSetToggleStreamPreviewVideo = () => {
	const setShowStreamPreviewVideo   = useSetAtom ( showStreamPreviewVideo );
	const setToggleIsStreamPreviewing = useSetToggleIsStreamPreviewing ();

	return value => {
		if ( value === undefined )
			return;

		setShowStreamPreviewVideo ( value );
		setToggleIsStreamPreviewing ( true );
	};
};

export const useGetToggleStreamPreviewVideo = () => {
	return useAtomValue ( showStreamPreviewVideo );
};

export const useSetToggleIsStreamPreviewing = () => {
	return useSetAtom ( isStreamPreviewing );
};

export const useGetToggleIsStreamPreviewing = () => {
	return useAtomValue ( isStreamPreviewing );
};

export const useSetToggleEditSettingsModal = () => {
	return useSetAtom ( isOpenEditSettingsModal );
};

export const useGetToggleEditSettingsModal = () => {
	return useAtomValue ( isOpenEditSettingsModal );
};

export const useSetStreamExperienceRating = () => {
	return useSetAtom ( streamExperienceRating );
};

export const useGetStreamExperienceRating = () => {
	return useAtomValue ( streamExperienceRating );
};

export const useSetStreamSummary = () => {
	return useSetAtom ( streamSummary );
};

export const useGetStreamSummary = () => {
	return useAtomValue ( streamSummary );
};

export const useSetConcurrentViewersChartData = () => {
	return useSetAtom ( concurrentViewersChartData );
};

export const useGetConcurrentViewersChartData = () => {
	return useAtomValue ( concurrentViewersChartData );
};

export const useSetModerators = () => {
	return useSetAtom ( moderatorsList );
};

export const useGetModerators = () => {
	return useAtomValue ( moderatorsList );
};

export const useSetRemoveSoftDeletedMod = () => {
	let moderatorsList      = useGetModerators ();
	const setModeratorsList = useSetModerators ();

	return () => {
		const updatedModeratorsList = moderatorsList.filter ( user => !user.isRemoved );

		setModeratorsList ( updatedModeratorsList );
	};
};

export const useSoftDeleteMod = () => {
	let moderatorsList      = useGetModerators ();
	const setModeratorsList = useSetModerators ();

	return value => {
		if ( !value )
			return;

		const updatedList = moderatorsList.map ( mod => {
			if ( mod.id === value.id ) {
				mod.isRemoved = true;
			}

			return mod;
		} );

		setModeratorsList ( updatedList );
	};
};

export const useSetCategoryTab = () => {
	return useSetAtom ( categoryTab );
};

export const useGetCategoryTab = () => {
	return useAtomValue ( categoryTab );
};

export const useSetIrlGameData = () => {
	return useSetAtom ( irlGameData );
};

export const useGetIrlGameData = () => {
	return useAtomValue ( irlGameData );
};

export const useGetPollsOpen = () => {
	return useAtomValue ( pollsOpenAtom );
};

export const useSetPollsOpen = () => {
	return useSetAtom ( pollsOpenAtom );
};
export const useGetGlobalPollsCount = () => {
	return useAtomValue ( pollsCountAtom );
};

export const useSetGlobalPollsCount = () => {
	return useSetAtom ( pollsCountAtom );
};
export const useSetPollsEndedData = () => {
	return useSetAtom ( pollsEndedDataAtom );
};
export const useGetPollsEndedData = () => {
	return useAtomValue ( pollsEndedDataAtom );
};
export const useSetPollsStartedData = () => {
	return useSetAtom ( pollsStartedDataAtom );
};

export const useGetPollsStartedData = () => {
	return useAtomValue ( pollsStartedDataAtom );
};
