import { LoadingOverlay, Modal } from "@mantine/core";

import { PrimaryButton }        from "_components/atoms/button";
import Icon                     from "_components/atoms/icon";
import { customLoader }         from "_components/atoms/loader/presentation";
import Render                   from "_components/atoms/render";
import Typography               from "_components/atoms/typography";
import {
	useGetStreamHealthCheckModal,
	useSetStreamHealthCheckModal
} from "_components/pages/studio/states";

import useStyles from "./styles";

const component = ( {
	state,
	handleRetry
} ) => {
	const { classes }                  = useStyles ();
	const setStreamHealthCheckModal    = useSetStreamHealthCheckModal ();
	const isStreamHealthCheckModalOpen = useGetStreamHealthCheckModal ();

	return (
		<Modal
			centered        = { true }
			classNames      = { {
				content : classes.modalContent,
				body    : classes.modalBody
			} }
			onClose         = { () => setStreamHealthCheckModal ( false ) }
			opened          = { isStreamHealthCheckModalOpen }
			withCloseButton = { false }
		>
			<Inner handleRetry = { handleRetry }
				state             = { state }
			/>
		</Modal>
	);
};

export default component;

function Inner ( { state, handleRetry } ) {
	const { classes } = useStyles ();

	if ( state.loading )
		return (
			<>
				<div className = { classes.loaderWrapper }>
					<LoadingOverlay
						visible
						loader         = { customLoader }
						overlayOpacity = { 0 }
						size           = { "xl" }
					/>
				</div>

				<Typography title = { "Checking Stream Health..." }
					weight           = { 500 }
				/>
			</>
		);

	if ( state.success ) {
		return (
			<>
				<Icon name       = { "tickDoneGreen" }
					sameInBothTheme = { true }
					size            = { 64 }
				/>

				<Typography title = { "You are now Live" }
					weight           = { 500 }
				/>
			</>
		);
	}

	if ( state.alert ) {
		return (
			<>
				<Icon name       = { "errorAlert" }
					sameInBothTheme = { true }
					size            = { 64 }
				/>

				<Typography title = { "Error Occurred" }
					weight           = { 500 }
				/>

				<Render condition = { state?.description }>
					<Typography color = { "secondary" }
						lineClamp        = { 2 }
						size             = { "sm" }
						title            = { state?.description || "" }
					/>
				</Render>

				<PrimaryButton
					fullWidth = { true }
					onClick   = { handleRetry }
					title     = { "Retry" }
				/>
			</>
		);

	}

}