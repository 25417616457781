import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {

	return {
		root: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "space-between",
			width          : "100%",
			height         : "100%"
		},
		rootContainer: {
			padding       : "0 0 15px 0",
			borderRadius  : "10px",
			width         : "100%",
			height        : "100%",
			display       : "flex",
			alignItems    : "flex-start",
			flexDirection : "row"
		},
		detailsContainer: {
			width         : "100%",
			display       : "flex",
			overflow      : "hidden",
			flexDirection : "column",
			alignItems    : "flex-end",
			height        : "100%"
		},
		image: {
			borderRadius : "8px",
			marginRight  : "15px"
		},
		uploadingVideo: {
			fontSize                : "1.5rem",
			fontWeight              : "500",
			textAlign               : "left",
			textTransform           : "capitalize",
			width                   : "fit-content",
			whiteSpace              : "nowrap",
			animation               : "marquee1 15s infinite",
			animationTimingFunction : "linear",
			"@keyframes marquee1"   : {
				"0%": {
					transform: "translateX(30%)"
					// opacity   : "1"
				},
				"100%": {
					transform: "translateX(-100%)"
					// opacity   : "0"
				}
			}

		},

		header: {
			width          : "100%",
			// minWidth       : "230px",
			gap            : "0.5rem",
			display        : "flex",
			alignItems     : "center",
			justifyContent : "space-between",
			paddingBottom  : "10px",
			textTransform  : "capitalize"
		},
		uploadSizeText: {
		// padding  : "10px 0 10px 10px",
			whiteSpace : "nowrap",
			fontSize   : "1.4rem"
		},
		linearProgress: {
			borderRadius : "10px",
			width        : "100%"
		},
		cancelBtn: {
		// marginTop     : "18px",
			float     : "right",
			fontSize  : "1.4rem",
			textAlign : "left",
			// color         : "#126bd5",
			cursor    : "pointer",
			color     : theme.other.paletteNew.tertiaryErrorRed

		},
		actionsBtns: {
			marginTop : "1rem",
			display   : "flex"
		},
		resumeBtn: {
			color            : theme.other.paletteNew.primaryDarkBlue,
			fontSize         : "1.4rem",
			marginRight      : "1rem",
			cursor           : "pointer",
			"[data-loading]" : {
				cursor: "default"
			},

			"&.loading": {
				color: theme.other.paletteNew.secondaryDarkGrey
			}
		},
		imageIcon: {
			height : "4rem",
			width  : "4rem"
		},
		imageContainer: {
			width          : "100%",
			display        : "flex",
			justifyContent : "center",
			alignItems     : "center",
			background     : theme.other.paletteNew.darkModeDarkGrey20,
			aspectRatio    : "16 / 9",
			borderRadius   : "1rem"
		},
		infoIcon: {
			height      : "2rem",
			width       : "2rem",
			marginRight : "0.4rem"
		},
		infoText: {
			fontSize: "1.2rem"
		},
		resumeInfoContainer: {
			marginBottom : "1rem",
			display      : "flex"
		},
		resumeBtnContainer: {
			display    : "flex",
			alignItems : "center"
		},
		resumeIcon: {
			height      : "1.5rem",
			width       : "1.5rem",
			marginRight : "0.25rem"

		},
		processingWrapperRoot: {
			display    : "flex",
			alignItems : "flex-start",
			gap        : "1rem"
		},
		processingWrapper: {
			display        : "flex",
			flexDirection  : "column",
			alignItems     : "center",
			justifyContent : "end"
		},
		btnWrapper: {
			display        : "flex",
			gap            : "1rem",
			alignItems     : "center",
			justifyContent : "end",
			paddingBottom  : "1rem",
			width          : "100%"
		},
		clearBtn: {
			color    : theme.other.colors.secondaryBtn.hover.text,
			":hover" : {
				background: theme.other.colors.secondaryBtn.hover.background
			}
		},
		btn: {
			padding      : "0.8rem 2rem",
			borderRadius : "1rem",
			background   : "none",
			border       : "none"
		},
		tickFilledIcon: {
			marginTop : "0.2rem",
			width     : "2.2rem"
		},
		viewBtn: {
			color    : theme.other.colors.secondaryBtn.clicked.text,
			":hover" : {
				background: theme.other.colors.secondaryBtn.hover.background
			}
		},
		divider: {
			borderTop : `solid 1px ${ theme.other.colors.border.primary }`,
			width     : "calc( 100% + 3rem)",
			position  : "relative",
			left      : "-1.5rem"
		}
	};
} );

