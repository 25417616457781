import { atom, useAtomValue, useSetAtom }     from "jotai";
import { RESET, atomWithReset, useResetAtom } from "jotai/utils";

import { CHUNK_SIZE, MULTIPART_UPLOAD_LIMIT, THREADS_LIMIT } from "_config/index";

const defaultLanguage = {
	"localeKey"       : "hi",
	"name"            : "हिंदी",
	"image"           : "https://s3-ap-southeast-1.amazonaws.com/rooter-images/languages/hindi.png",
	"priority"        : 1,
	"localeInEnglish" : "Hindi"
};

export const uploadParamsAtom = atomWithReset ( {
	title               : "",
	videoFile           : null, // this videoFile field is used for both videos and reels
	thumbnailUrl        : null,
	source              : null,
	thumbnailId         : null,
	thumbnail           : undefined,
	isUploadedThumbnail : false,
	streamDescription   : "",
	game                : {},
	streamLanguage      : defaultLanguage,
	tagsList            : []
} );
export const uploadReelsParamsAtom = atomWithReset ( {
	title             : "",
	source            : null,
	streamDescription : "",
	game              : {},
	streamLanguage    : defaultLanguage,
	tagsList          : []
} );
export const uploadSizeInfoAtom = atomWithReset ( {
	progressEventLoaded : 0,
	progressEventTotal  : 0
} );
export const percentCompletedAtom = atomWithReset ( 0 );

export const uploadingStates = {
	UPLOAD_PENDING   : "UPLOAD_PENDING",
	UPLOADING        : "UPLOADING",
	INITIATED        : "INITIATED",
	UPLOAD_FINISHED  : "UPLOAD_FINISHED",
	UPLOAD_CANCELLED : "UPLOAD_CANCELLED",
	UPLOAD_ERRORED   : "UPLOAD_ERRORED"
};

export const UPLOAD_TYPE_CONFIG = {
	VIDEOS           : "video",
	REELS            : "reel",
	MEDIA_TYPE_VIDEO : "video",
	MEDIA_TYPE_AUDIO : "audio"
};

export const isUploadingVideoAtom = atomWithReset ( uploadingStates.UPLOAD_PENDING );
export const uploadTypeAtom = atomWithReset ( "" );
export const inProcessingVideoItemAtom = atom ( null );
export const uploadInProgressModalAtom = atom ( false );
export const uploadContentNotificationAtom = atom ( null );

export const secondsToViewAtom = atomWithReset ( 0 );
export const uploadBtnAtom = atomWithReset ( "" );
export const isSelectedDifferentFile = atomWithReset ( false );
export const inProgressVideoDetails = atomWithReset ( null ); // object of inprogress video details
export const opneVideoModalExternal = atomWithReset ( false );
export const uploadConfig = atomWithReset ( {
	multipartUploadLimit : MULTIPART_UPLOAD_LIMIT, // MB
	chunksSize           : CHUNK_SIZE, // MB
	threadsLimit         : THREADS_LIMIT
} );
export const setCancelUploadFunctionAtom = atomWithReset ( { fn: () => {} } );

export const uploadThumbnailUrlAtom = atom ( get => get ( uploadParamsAtom )?.thumbnailUrl );
export const uploadVideoFileAtom = atom ( get => get ( uploadParamsAtom )?.videoFile );

export const useGetUploadNotification = () => {
	return useAtomValue ( uploadContentNotificationAtom );
};

export const useSetUploadNotification = () => {
	return useSetAtom ( uploadContentNotificationAtom );
};
export const useGetUploadThumbnailUrl = () => {
	return useAtomValue ( uploadThumbnailUrlAtom );
};

export const useGetUploadVideoFile = () => {
	return useAtomValue ( uploadVideoFileAtom );
};

export const useSetUploadParamsAtom = () => {
	return useSetAtom ( uploadParamsAtom );
};

export const useGetUploadParamsAtom = () => {
	return useAtomValue ( uploadParamsAtom );
};

export const useSetUploadReelsParamsAtom = () => {
	return useSetAtom ( uploadReelsParamsAtom );
};

export const useGetUploadReelsParamsAtom = () => {
	return useAtomValue ( uploadReelsParamsAtom );
};

export const useSetUploadSizeInfoAtom = () => {
	return useSetAtom ( uploadSizeInfoAtom );
};

export const useGetUploadSizeInfoAtom = () => {
	return useAtomValue ( uploadSizeInfoAtom );
};

export const useSetPercentCompletedAtom = () => {
	return useSetAtom ( percentCompletedAtom );
};

export const useGetPercentCompletedAtom = () => {
	return useAtomValue ( percentCompletedAtom );
};

export const useSetUploadInProgressModal = () => {
	return useSetAtom ( uploadInProgressModalAtom );
};

export const useGetUploadInProgressModal = () => {
	return useAtomValue ( uploadInProgressModalAtom );
};
export const useSetIsUploadingVideo = () => {
	return useSetAtom ( isUploadingVideoAtom );
};

export const useGetIsUploadingVideo = () => {
	return useAtomValue ( isUploadingVideoAtom );
};

export const useSetUploadTypeAtom = () => {
	return useSetAtom ( uploadTypeAtom );
};

export const useGetUploadTypeAtom = () => {
	return useAtomValue ( uploadTypeAtom );
};

export const useSetSecondsToViewAtom = () => {
	return useSetAtom ( secondsToViewAtom );
};

export const useGetSecondsToViewAtom = () => {
	return useAtomValue ( secondsToViewAtom );
};

export const useSetUploadBtnAtom = () => {
	return useSetAtom ( uploadBtnAtom );
};

export const useGetUploadBtnAtom = () => {
	return useAtomValue ( uploadBtnAtom );
};

export const useSetCancelUploadFunction = () => {
	return useSetAtom ( setCancelUploadFunctionAtom );
};

export const useGetCancelUploadFunction = () => {
	return useAtomValue ( setCancelUploadFunctionAtom );
};

export const useSetInProgressVideoDetails = () => {
	return useSetAtom ( inProgressVideoDetails );
};

export const useGetInProgressVideoDetails = () => {
	return useAtomValue ( inProgressVideoDetails );
};

export const useSetInProcessingItem = () => {
	return useSetAtom ( inProcessingVideoItemAtom );
};

export const useGetInProcessingItem = () => {
	return useAtomValue ( inProcessingVideoItemAtom );
};

export const useSetVideoModal = () => {
	return useSetAtom ( opneVideoModalExternal );
};

export const useGetVideoModal = () => {
	return useAtomValue ( opneVideoModalExternal );
};
export const useGetIsSelectedDifferentFile = () => {
	return useAtomValue ( isSelectedDifferentFile );
};
export const useSetIsSelectedDifferentFile = () => {
	return useSetAtom ( isSelectedDifferentFile );
};

export const useGetUploadConfig = () => {
	return useAtomValue ( uploadConfig );
};
export const useSetUploadConfig = () => {
	return useSetAtom ( uploadConfig );
};

export const useResetContentUpload = () => {
	const resetUploadParamsAtom            = useResetAtom ( uploadParamsAtom );
	const resetUploadReelsParamsAtom       = useResetAtom ( uploadReelsParamsAtom );
	const resetUploadSizeInfoAtom          = useResetAtom ( uploadSizeInfoAtom );
	const resetPercentCompletedAtom        = useResetAtom ( percentCompletedAtom );
	const resetIsUploadingVideoAtom        = useResetAtom ( isUploadingVideoAtom );
	const resetUploadTypeAtom              = useResetAtom ( uploadTypeAtom );
	const resetSecondsToViewAtom           = useResetAtom ( secondsToViewAtom );
	const resetUploadBtnAtom               = useResetAtom ( uploadBtnAtom );
	const resetSetCancelUploadFunctionAtom = useResetAtom ( setCancelUploadFunctionAtom );

	return () => {
		// Call all reset funcitons here
		resetUploadParamsAtom ( RESET );
		resetUploadReelsParamsAtom ( RESET );
		resetUploadSizeInfoAtom ( RESET );
		resetPercentCompletedAtom ( RESET );
		resetIsUploadingVideoAtom ( RESET );
		resetUploadTypeAtom ( RESET );
		resetSecondsToViewAtom ( RESET );
		resetUploadBtnAtom ( RESET );
		resetSetCancelUploadFunctionAtom ( RESET );
	};
};
