import { Menu, Skeleton } from "@mantine/core";
import dynamic            from "next/dynamic";
import { Fragment }       from "react";

import { useGetDeviceType }   from "_common/hooks/global";
import { UPLOAD_TYPE_CONFIG } from "_common/hooks/uploadVideo";
import zIndex                 from "_common/zIndex";
import { PrimaryButton }      from "_components/atoms/button";
import Icon                   from "_components/atoms/icon";
import MultipleStreamModal    from "_components/organisms/multipleStreamModal";
import UploadInProgressModal  from "_components/organisms/uploadVideo/components/uploadInProgressModal";
import useStyles              from "_styles/organisms/navigationMenu";

const UploadVideoModal = dynamic ( () => import ( "_components/organisms/uploadVideo" ), { ssr: false } );

export default function BasicMenu ( {
	fetchStreams,
	isDepleted,
	isFetching,
	multiStream,
	// onCreateNew,
	onGoLive,
	selectStream,
	streams,
	modalOpen,
	handleModalClose,
	handleClose,
	handleStream,
	handleModalOpen,
	handleClick,
	open,
	handleVideoModal,
	closeVideoModal,
	videoModal,
	uploadType,
	isLoggedIn,
	isFetchingSessionStatus,
	showCurrentStreamMenu,
	sessionData,
	handleNewMultiStream,
	buttonClass,
	uploadInProgressModal,
	setUploadInProgressModal
} ) {

	const { classes } = useStyles ( undefined, { name: "styles" } );
	const deviceType  = useGetDeviceType ();

	const LoadingState = () => {
		return (
			Array ( 3 ).fill ( 1 ).map ( ( el, idx ) => (
				<Menu.Item key = { idx }
					className     = { classes.loaderMenuItem }
				>
					<div className = { `${ classes.menuItemContainer } fullWidth` }>
						<Skeleton
							circle
							height = { 50 }
							m      = "sm"
						/>

						<Skeleton
							className = { classes.rectangularSekleton }
							height    = { 30 }
							m         = "xs"
							mr        = "xl"
							radius    = { "md" }
						/>
					</div>
				</Menu.Item>

			)
			)
		);
	};

	return (
		<Fragment>
			{
				( isLoggedIn ) ? (
					<UploadVideoModal
						handleModalClose = { closeVideoModal }
						modalOpen        = { videoModal }
						uploadType       = { uploadType }
					/>
				)
					: null
			}

			<UploadInProgressModal
				isOpen  = { uploadInProgressModal }
				onClose = { () => setUploadInProgressModal ( false ) }
			/>

			<>

				<Menu
					className  = { classes.menuContainer }
					classNames = { { dropdown: classes.dropdown, item: classes.menuItem } }
					id         = "basic-menu"
					offset     = { 2 }
					onClose    = { handleClose }
					opened     = { open }
					radius     = { "lg" }
					width      = { "20rem" }
					zIndex     = { zIndex.header.root }
				>
					<Menu.Target>
						<div>
							{
								deviceType === "desktop" ? (
									<PrimaryButton
										className = { classes.createBtn }
										id        = "basic-button"
										onClick   = { handleClick }
										title     = { "Create" }
									/>

								)
									: null
							}
						</div>

					</Menu.Target>

					<Menu.Dropdown>

						{
							isFetchingSessionStatus ?
								<LoadingState />
								: null
						}

						{
							isFetchingSessionStatus ? null :

								multiStream ? (
									<>
										{
											showCurrentStreamMenu ? (
												<Menu.Item onClick = { handleModalOpen }>
													<span className = { classes.menuItemContainer }>
														{/* <StreamSettingsActiveIcon className = { classes.menuIcons } /> */}

														<Icon
															sameInBothTheme
															className = { classes.menuIcons }
															name      = "live"
														/>

														<div className = { classes.title }>
															View Current Streams
														</div>

													</span>
												</Menu.Item>
											) : null
										}

										<Menu.Item onClick = { handleNewMultiStream }>
											<span className = { classes.menuItemContainer }>
												{/* <GameControllerIconActive className = { classes.menuIcons } /> */}

												<Icon
													sameInBothTheme
													className = { classes.menuIcons }
													name      = "live"
												/>

												<div className = { classes.title }>
													Create New Stream
												</div>
											</span>
										</Menu.Item>
									</>
								)
									: (
										<Menu.Item onClick = { handleStream }>
											<span className = { classes.menuItemContainer }>
												{/* <GameControllerIconActive className = { classes.menuIcons } /> */}

												{/* <div> */}
												<Icon
													sameInBothTheme
													className = { classes.menuIcons }
													name      = "live"
												/>

												<div className = { classes.title }>
													{sessionData.isLive ? "View Current Stream" : "Streaming"}
												</div>
											</span>
										</Menu.Item>
									)
						}

						{
							isFetchingSessionStatus ? null : (
								<Menu.Item onClick = { () => handleVideoModal ( UPLOAD_TYPE_CONFIG.VIDEOS ) }>
									<span className = { classes.menuItemContainer }>
										{/* <VideocamIconActive className = { classes.menuIcons } /> */}

										<Icon
											sameInBothTheme
											className = { classes.menuIcons }
											name      = "camera"
										/>

										<div className = { classes.title }>
											Upload Video
										</div>
									</span>
								</Menu.Item>
							)
						}

						{isFetchingSessionStatus ? null : (
							<Menu.Item onClick = { () => handleVideoModal ( UPLOAD_TYPE_CONFIG.REELS ) }>
								<span className = { classes.menuItemContainer }>

									{/* <ReelsIcon className = { classes.menuIcons } /> */}

									<Icon
										sameInBothTheme
										className = { classes.menuIcons }
										name      = "reels"
									/>

									<div className = { classes.title }>
										Upload Reels
									</div>
								</span>

							</Menu.Item>
						)}
					</Menu.Dropdown>

				</Menu>

				{
					modalOpen && (
						<MultipleStreamModal
							fetchData        = { fetchStreams }
							handleModalClose = { handleModalClose }
							isDepleted       = { isDepleted }
							isFetching       = { isFetching }
							modalOpen        = { modalOpen }
							onCreateNew      = { handleNewMultiStream }
							onGoLive         = { onGoLive }
							onStreamSelect   = { selectStream }
							streams          = { streams }
						/>
					)
				}

			</>
		</Fragment>
	);
}
