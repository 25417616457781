import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {

	return {
		root: {
			width: "100%"
		},
		linearProgress: {
			borderRadius    : "10px !important",
			backgroundColor : theme.other.colors.border.primary,
			height          : "7px"
		}
	};
} );

