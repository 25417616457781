import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props, u ) => ( {
	popOver: {
		backgroundColor        : theme.other.darkMode ? theme.other.paletteNew.darkModeDarkGrey : theme.other.paletteNew?.primaryBackgroundGrey,
		maxHeight              : "400px",
		minWidth               : "500px",
		height                 : "fit-content",
		minHeight              : "300px",
		borderRadius           : "20px",
		border                 : "none",
		zIndex                 : 100,
		overflowY              : "scroll",
		boxShadow              : "0 0 40px 10px rgba(0, 0, 0, 0.1)",
		padding                : ( props?.searchTerm?.length > 2 ) ? "0" : "2rem",
		display                : "block",
		"&::-webkit-scrollbar" : {
			width      : "0", /* Remove scrollbar space */
			height     : "0",
			background : "transparent" /* Optional: just make scrollbar invisible */
		}
	},
	headerParent: {
		height       : "70px",
		width        : "100%",
		display      : "flex",
		alignItems   : "center",
		// justifyContent : "center",
		// borderBottom   : "solid 2px #f0f0f0",
		borderBottom : `solid 0.75px ${ theme.other.colors?.border.primary }`,
		boxShadow    : "none",
		position     : "fixed",
		top          : "0",
		zIndex       : "1115"
	},
	placeholder: {
		fontSize: "1rem !important"
	},
	headerWrapper: {
		width          : "100%",
		display        : "flex",
		alignItems     : "center",
		justifyContent : "space-between",
		padding        : "0 1.5rem"
	},
	userGemsContainer: {
		display      : "flex",
		alignItems   : "center",
		background   : theme.other.paletteNew.mainGradient,
		position     : "relative",
		borderRadius : "20px",
		padding      : "0.1rem 1.5rem"
		// border       : "0.5px solid rgba(255, 255, 255, 0.3)",
		// marginRight  : "3rem"
	},
	diamondIconContainer: {
		position   : "absolute",
		right      : "-6px",
		lineHeight : "0"
	},
	icon: {
		height : "2.25rem",
		width  : "2.25rem"
	},
	studioShareIcon: {
		width       : "2.8rem",
		cursor      : "pointer",
		marginRight : "1rem"
	},
	goLiveBtn: {
		position        : "relative",
		backgroundColor : "#56a700",
		marginRight     : "2.5rem",
		marginLeft      : "1rem",
		borderRadius    : "1rem",
		height          : "100%",
		"&:hover"       : {
			backgroundColor: "#56a700"
		},
		"&[data-disabled]": {
			backgroundColor: "rgba(86, 167, 0, 0.5)"
		},
		minWidth: "10rem"
	},
	headerChild: {
		width          : "33%",
		display        : "flex",
		justifyContent : "center"
	},
	searchBar: {
		display         : "flex",
		alignItems      : "center",
		justifyContent  : "space-between",
		maxWidth        : "415px",
		width           : "85%",
		margin          : "auto",
		padding         : "1rem 1.5rem",
		borderRadius    : "10px",
		backgroundColor : theme.other.darkMode ?
			theme.other.paletteNew.darkModeDarkGrey :
			theme.other.paletteNew.secondaryBackgroundGrey
	},
	dashboardPageLogo: {
		width: "20rem"
	},
	profilePicContainer: {
		display    : "flex",
		gap        : "1rem",
		padding    : "0 1rem",
		alignItems : "center"
	},
	profileTextContainer: {
		marginTop: "0.3rem"
	},
	viewProfileText: {
		fontSize : "1.2rem",
		margin   : 0,
		color    : "#929292"
	},
	logoutContainer: {
		borderTop : `1px solid ${ theme.other.colors.border.primary }`,
		marginTop : "1rem",
		padding   : "0.75rem 1.5rem !important"
	},
	logoutTextStyles: {
		textTransform: "none !important"
		// marginTop     : "0.7rem !important"
	},
	miniProfilePic: {
		height       : "3rem",
		width        : "3rem",
		borderRadius : "50%"
	},
	rooterLogo: {
		width: "10.6rem"
		// height : "30px"
		// height : "28px"
	},
	svg: {
		height : "20px",
		width  : "20px",
		cursor : "pointer"
	},
	svgDark: {
		filter : "invert(1)",
		height : "19px",
		cursor : "pointer"
	},
	svgMenu: {
		height      : "28px",
		marginRight : "1.2em",
		cursor      : "pointer",
		transform   : "scale(0.9)"
	},
	loginContainer: {
		display        : "flex",
		alignItems     : "center",
		justifyContent : "flex-end",
		width          : props?.isStudioPage ? "fit-content" : "18%",

		[rooterMediaQuery.tablet]: {
			width: "25%"
		}
	},
	menuContainer: {
		all            : "unset",
		cursor         : "pointer",
		display        : "flex",
		justifyContent : "center",
		alignItems     : "center",
		borderRadius   : "50%",
		textTransform  : "none",
		// padding        : "0.2rem 1rem",

		"&:hover": {
			background: "none"
		}
	},
	authButtons: {
		// textTransform: "initial"
		textTransform : "none",
		borderRadius  : "10px"
		// padding       : "0.8rem 3rem"
	},
	avatarContainer: {
		// ...theme.other.rooterAvatars.size40
		display: "flex"
	},
	img: {
		...theme.other.responsiveImage,
		...theme.other.rooterAvatars.borderRadius,
		margin    : ".2rem",
		height    : "3rem",
		width     : "3rem",
		outline   : props?.isMenuOpen && !props?.isCreatorDashboard ? `2px solid ${ theme.other.paletteNew.primaryDarkBlue }` : "none",
		objectFit : "unset",

		"&:hover": {
			outline: !props?.isCreatorDashboard ? `2px solid ${ theme.other.paletteNew.primaryDarkBlue }` : "none"
		}
	},
	svgDarkIcon: {
		filter    : "invert(1)",
		transform : "scale(0.6)",
		cursor    : "pointer"
	},
	svgCross: {
		transform : "scale(0.6)",
		cursor    : "pointer"
	},
	svgSearch: {
		width       : "2rem",
		height      : "2rem",
		marginRight : "1rem"
	},
	svgIcon: {
		...theme.other.svgIcons.size20
	},
	darkIcon: {
		filter: "invert(1)"
	},
	signUpButton: {
	},
	searchContainerWrapper: {
		width: "40%"
	},
	cssFocused: {
		borderColor: "#126bd5 !important"
	},
	searchContainer: {
		display        : "flex",
		alignItems     : "center",
		justifyContent : "center",
		// width      : "60%",
		margin         : "auto"
	},
	searchBarField: {
		fontSize : "14px",
		padding  : "1rem"
	},
	searchBarContainer: {
		color           : theme.other.colors.text.primary,
		backgroundColor : theme.other.darkMode ? theme.other.paletteNew.darkModeDarkGrey : theme.other.paletteNew.secondaryBackgroundGrey,
		borderRadius    : "10px",

		fontSize                 : "1.5rem",
		"& .MuiTypography-body1" : {
			color: theme.other.darkMode ? theme.other.paletteNew.secondaryDarkGrey : "#00000080"
		}
	},

	notchedOutline: {
		border       : "none",
		borderRadius : "10px !important"
		// borderColor  : "#e7e7e7 !important"
	},
	topRightContainer: {
		display        : "flex",
		gap            : "1.5rem",
		alignItems     : "center",
		justifyContent : "flex-end"
	},
	viewProfile: {
	},
	item: {
		padding: "0.6rem 0"
	},
	itemLabel: {
		padding: "0"
	},
	userAssetsContainet: {
		"& .mantine-Menu-itemLabel": {
			margin         : "0.25rem 0",
			display        : "flex",
			alignItems     : "center",
			justifyContent : "center"
		}
	},
	goLiveText: {
		padding  : "0.8rem 1.5rem",
		fontSize : "1.4rem"
	},
	endStreamBtn: {
		position     : "relative",
		marginRight  : "2.5rem",
		marginLeft   : "1rem",
		borderRadius : "1rem",
		height       : "100%",
		borderWidth  : "1.5px",
		borderColor  : "red",
		minWidth     : "10rem"
	},
	endBtnText: {
		color    : "red",
		padding  : "0.8rem 1.5rem",
		fontSize : "1.4rem"
	},
	linearProgressContainer: {
		// border       : `solid 1px ${ theme.colors.border.primary }`,
		margin       : "15px",
		marginBottom : "0",
		borderRadius : "10px"
	},
	rooterStudioLogo: {
		width: "12rem"
	},
	tickIcon: {
		...theme.other.svgIcons.size20
	},
	themeMenuItem: {
		display        : "flex",
		justifyContent : "space-between",
		width          : "20rem"
	},
	appearenceContainer: {
		"&[data-hovered]": {
			backgroundColor: theme.other.darkMode ? theme.other.paletteNew.darkModeDarkGrey80 : theme.other.paletteNew.primaryBackgroundGrey
		},
		"& .mantine-Menu-itemLabel": {
			display        : "flex",
			justifyContent : "start",
			padding        : props?.isLogout ? "1.5rem 1rem !important" : "0.5rem 1.5rem",
			alignItems     : "center"
		}

	},
	appearenceSubContainer: {
		display    : "flex",
		alignItems : "center"
	},
	backIcon: {
		...theme.other.svgIcons.size20
	},
	menuRoot: {
	},
	mainMenuContainer: {
		maxWidth     : "230px",
		minWidth     : "180px",
		border       : "none",
		background   : theme.other.darkMode ? theme.other.paletteNew.darkModeInclinedBlack2 : theme.other.paletteNew.secondaryWhite,
		borderRadius : "1rem",
		padding      : "0",
		overflow     : "hidden",
		boxShadow    : theme.other.colors.boxShadow.primary
	},
	themeMenuContainer: {
		margin: "1rem 0"
	},
	seperator: {
		borderBottom: `1px solid ${ theme.other.colors.border.primary }`
	},
	mr2: {
		marginRight: "2rem"
	},
	themeIcon: {
		height      : "2.8rem",
		width       : "2.8rem",
		marginRight : "2.5rem",
		cursor      : "pointer"
	},
	moonIconContainer: {
		display    : "flex",
		alignItems : "center"
	},
	authBtnText: {
		whiteSpace   : "nowrap",
		overflow     : "hidden",
		textOverflow : "ellipsis",
		fontSize     : "1.4rem"
		// margin       : "0.8rem 3rem"
	},
	hamburgerIcon: {
		height      : "2.8rem",
		width       : "2.8rem",
		marginRight : "2rem",
		cursor      : "pointer"
	},
	headerLogoWrapper: {
		display    : "flex",
		alignItems : "center"
	},
	createButton: {
		// marginRight : "2rem",
		padding: "0.8rem 2rem"
	},
	createButtonContainer: {
		// marginRight: "3rem"
	},
	hamburgerIconButton: {
		all     : "unset",
		display : "flex"
	},
	creatorDashboardLogo: {
		display        : "flex",
		alignItems     : "center",
		justifyContent : "space-between",
		gap            : "1rem"
	},
	searchIcon: {
		height : "2.5rem",
		width  : "2.5rem",
		margin : "2px 10px"
	},

	circularUpload: {
		position: "relative"
	},
	uploadIconContainer: {
		position : "absolute",
		top      : "12px",
		left     : "12px"
	},
	uploadSvgIcon: {
		width       : "20px",
		aspectRatio : "1/1",
		position    : "absolute",
		top         : "49%",
		left        : "50%",
		transform   : "translate(-50%,-50%)"
	},
	menuWrapper: {
		// padding: "1rem"
	},
	loader: {
		position  : "absolute",
		left      : "50%",
		top       : "50%",
		transform : "translate(-50%,-50%)",
		"& g"     : {
			"& g": {
				"& circle": {
					stroke        : theme.other.paletteNew.darkModeWhite20,
					strokeOpacity : "1",
					strokeWidth   : "3px"

				},
				"& path": {
					strokeWidth: "3px"
				}
			}
		}

	},
	ringProgress: {
		position   : "absolute",
		"& circle" : {
			transition: "all 0.1s ease-out"
		}
	},
	ringSvg: {
		all: "unset"
		// transform : "rotate(90deg)"
	},
	proButton: {
		padding      : "0.1rem !important",
		overflow     : "hidden",
		borderRadius : "6rem !important",
		position     : "relative",
		margin       : "0 1rem",
		":after"     : {
			content         : "''",
			position        : "absolute",
			left            : "50%",
			top             : "50%",
			height          : "100%",
			width           : "100%",
			backgroundImage : theme.other.paletteNew.proGradient,
			zIndex          : "0",
			transform       : "translate(-50%, -50%)"
		}
	},
	proButtonInner: {
		position     : "relative",
		zIndex       : "1",
		background   : `${ theme.other.colors.background.secondary }!important`,
		padding      : "1rem",
		borderRadius : "10rem"
	},
	proButtonLabel: {
		background           : theme.other.paletteNew.proGradient,
		WebkitBackgroundClip : "text",
		WebkitTextFillColor  : "transparent"
	}

} ) );
