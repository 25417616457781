import Presentation from "./presentation";

const component = props => {

	return (
		<Presentation { ...props } />
	);
};

export default component;
