import { useGetDarkMode } from "_common/hooks/global";

import Presentation from "./presentation";

const component = props => {
	const darkMode = useGetDarkMode ();

	return (
		<Presentation
			{ ...props }
			darkMode = { darkMode }
		/>
	);
};

export default component;
