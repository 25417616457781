import { Transition } from "@mantine/core";

import Divider    from "_components/atoms/divider";
import Icon       from "_components/atoms/icon";
import Typography from "_components/atoms/typography";

import useStyles from "./styles";

const component = ( {
	successStatusObj
} ) => {

	return (
		<Transition
			duration       = { 400 }
			mounted        = { successStatusObj ? true : false }
			timingFunction = "ease"
			transition     = "slide-right"
		>
			{styles => (
				<div style = { styles }>
					<CompletedUI textObj  = { successStatusObj } />
				</div>
			)}
		</Transition>
	);
};

const CompletedUI = ( { textObj } ) => {
	const { classes } = useStyles ();

	return (
		<>
			<div className = { classes.videoUploadStatus }>
				<div className = { classes.titleContainer }>
					<Icon
						className       = { classes.crossIcon }
						name            = { textObj?.iconName }
						sameInBothTheme = { true }
					/>

					<Typography
						title = { textObj?.title }
					/>
				</div>

				<div className = { classes.videoUploadStatusButtons }>
					<Typography
						className = "rightSpacing cursorPointer"
						color     = { "secondary" }
						onClick   = { textObj?.rejectBtnText?.action }
						title     = { textObj?.rejectBtnText?.title }
					/>

					<Typography
						className = { classes.retryBtn }
						onClick   = { textObj?.acceptBtnText?.action }
						title     = { textObj?.acceptBtnText?.title }
					/>
				</div>

			</div>

			<Divider />
		</>
	);
};

export default component;
