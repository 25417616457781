import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		modalContent: {
			borderRadius : "2rem",
			minHeight    : "200px",
			flexBasis    : "min(400px,90%)"
		},
		modalBody: {
			display        : "flex",
			borderRadius   : "2rem",
			padding        : "2rem",
			alignItems     : "center",
			gap            : "2rem",
			minHeight      : "inherit",
			justifyContent : "center",
			flexDirection  : "column",
			textAlign      : "center"
		},
		loaderWrapper: {

			position    : "relative",
			height      : "6.4rem",
			aspectRatio : "1/1"

		}
	};
} );