import dynamic       from "next/dynamic";
import Link          from "next/link";
import { useRouter } from "next/router";

import { useGetProStatus }                     from "_common/hooks/auth";
import { useGetDarkMode, useGetDeviceType }    from "_common/hooks/global";
import { useOpenProModal }                     from "_common/hooks/useOpenProModal";
import { useAddQueryParam }                    from "_common/hooks/useQueryParams";
import { isCreatorDashboardPage, isPageRoute } from "_common/utils";
import { createURLSearchParams }               from "_common/webview";
import BackgroundWrapper                       from "_components/atoms/backgroundWrapper";
import BaseButton                              from "_components/atoms/button/baseButton";
import Icon                                    from "_components/atoms/icon";
import Image                                   from "_components/atoms/image";
import Render                                  from "_components/atoms/render";
import ConfirmModal                            from "_components/molecules/confirmModal";
import HeaderMenuItems                         from "_components/molecules/headerMenuItems";
import useStyles                               from "_styles/organisms/header";

const SearchInput = dynamic ( () => import ( "./components/searchInput/container" ) );

const component = props => {
	const {
		fullWidth,
		isStudioPage,
		onClickHamburgerMenu,
		router,
		isMobile
	} = props;

	const darkMode           = useGetDarkMode ();
	const { classes }        = useStyles ( { fullWidth }, { name: "header" } );
	const deviceType         = useGetDeviceType ();
	const isCreatorDashboard = isCreatorDashboardPage ( { router } );
	const checkPageRoute     = page => isPageRoute ( { router, page } );

	const hideSearch = checkPageRoute ( "studio" ) || checkPageRoute ( "growth-dashboard" ) || checkPageRoute ( "creator-dashboard" );
	const isWebView  = router.query.platform === "android" || router.query.platform === "ios";

	return (
		<BackgroundWrapper className = { classes.headerParent }
			type                        = "secondary"
		>
			<div className = { classes.headerWrapper }>

				<RooterLogo
					darkMode             = { darkMode }
					isStudioPage         = { isStudioPage }
					isWebView            = { isWebView }
					onClickHamburgerMenu = { onClickHamburgerMenu }
				/>

				<Render condition = { !hideSearch && deviceType === "desktop" }>
					<SearchInput />
				</Render>

				<Render condition = { !isWebView }>
					<HeaderMenuItems
						isCreatorDashboard = { isCreatorDashboard }
						isMobile           = { isMobile }
						showShareIcon      = { isStudioPage }
					/>
				</Render>
			</div>
		</BackgroundWrapper>
	);
};

export default component;

function RooterLogo ( { isStudioPage, onClickHamburgerMenu, darkMode, isWebView } ) {

	const router             = useRouter ();
	const isCreatorDashboard = isCreatorDashboardPage ( { router } );

	if ( isStudioPage ) {
		return (
			<StudioPageLogo
				darkMode             = { darkMode }
				onClickHamburgerMenu = { onClickHamburgerMenu }
			/>
		);
	}

	if ( isCreatorDashboard ) {
		const params = createURLSearchParams ( router.query );

		return (
			<CreatorDashboardLogo
				darkMode  = { darkMode }
				isWebView = { isWebView }
				params    = { params }
			/>
		);
	}

	return (
		<DefaultLogo
			darkMode             = { darkMode }
			onClickHamburgerMenu = { onClickHamburgerMenu }
		/>
	);
}

function DefaultLogo ( { onClickHamburgerMenu, darkMode } ) {

	const { classes } = useStyles ( undefined, { name: "header" } );

	const isPro         = useGetProStatus ();
	const openProModal  = useOpenProModal ();
	const addQueryParam = useAddQueryParam ();

	function handleOpenProModal () {
		if ( isPro ) {
			addQueryParam ( { alreadyPro: "true" } );

			return;
		}

		openProModal ();
	}

	return (
		<div className = { classes.headerLogoWrapper }>
			<button className = { classes.hamburgerIconButton }
				onClick          = { onClickHamburgerMenu }
			>
				<Icon className = { classes.hamburgerIcon }
					name           = "hamburgerMenu"
				/>
			</button>

			<Link
				passHref
				href = "/"
			>
				<Render condition = { darkMode }>
					<Image className = { `${ classes.rooterLogo } cursorPointer` }
						src             = "/images/rooter-logo-white.webp"
					/>
				</Render>

				<Render condition = { !darkMode }>
					<Image className = { `${ classes.rooterLogo } cursorPointer` }
						src             = "/images/rooter-logo-black.webp"
					/>
				</Render>

			</Link>

			<BaseButton
				classNames  = { {
					root  : classes.proButton,
					inner : classes.proButtonInner,
					label : classes.proButtonLabel
				} }
				leftSection = { (
					<Icon sameInBothTheme
						name = "proShine"
						size = { 15 }
					/>
				) }
				onClick     = { handleOpenProModal }
				title       = { isPro ? "You are Pro" : "Buy Pro" }
				variant     = { "transparent" }
			/>

		</div>
	);
}

function StudioPageLogo ( { onClickHamburgerMenu, darkMode } ) {

	const { classes } = useStyles ( undefined, { name: "header" } );
	const router      = useRouter ();

	return (
		<div className = { classes.headerLogoWrapper }>
			<Icon className = { classes.hamburgerIcon }
				name           = "hamburgerMenu"
				onClick        = { onClickHamburgerMenu }
			/>

			<ConfirmModal
				buttonComponent = {
					darkMode ? (
						<Image className = { `${ classes.rooterStudioLogo } cursorPointer` }
							src             = "/images/rooter-studio-logo-white.webp"
						/>
					) : (
						<Icon className = { `${ classes.rooterStudioLogo } cursorPointer` }
							name           = "rooterStudioLogo"
						/>
					)
				}
				dialogText      = "Are you sure you want to exit this page?"
				performAction   = { () => router.push ( "/" ) }
			/>
		</div>
	);
}

function CreatorDashboardLogo ( { isWebView, params, darkMode } ) {

	const { classes } = useStyles ( undefined, { name: "header" } );
	const router      = useRouter ();

	const onClose = () => {
		if ( router.query.platform === "android" )
			window?.Android?.closeActivity ();

		if ( router.query.platform === "ios" ) {
			// Send a message with arguments to the native iOS code
			let dataToSend = {
				action: "closeActivity" // action is always name of the JS function

			};

			window?.webkit?.messageHandlers?.ios_handler?.postMessage ( JSON.stringify ( dataToSend ) );
		}
	};

	return (
		<div className = { classes.creatorDashboardLogo }>
			<Render condition = { isWebView }>
				<Icon name = "crossIcon"
					onClick   = { onClose }
					size      = { 40 }
				/>

			</Render>

			<div className = { classes.headerLogoWrapper }
				onClick       = { () => router.push ( `/creator-dashboard/overview${ params }` ) }
			>
				<Render condition = { darkMode }>
					<Image className = { `${ classes.rooterStudioLogo } cursorPointer` }
						src             = "/images/rooter-studio-cd-logo-white.webp"
					/>
				</Render>

				<Render condition = { !darkMode }>
					<Icon className = { `${ classes.rooterStudioLogo } cursorPointer` }
						name           = "rooterCdStudio"
					>
					</Icon>

				</Render>
			</div>

		</div>
	);
}
