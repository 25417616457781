import { createStyles } from "@mantine/emotion";

export default createStyles ( {
	dialogRoot: {
		display        : "flex",
		alignItems     : "center",
		justifyContent : "center"
	},
	dialogTitle: {
		display        : "flex",
		borderBottom   : "1px solid #e7e7e7",
		alignItems     : "center",
		justifyContent : "space-between"
	},
	modalHeader: {
		fontSize   : "1.7rem",
		fontWeight : "500"
	},
	cardListRoot: {
		display        : "flex",
		gap            : "1rem",
		fontSize       : "1.5rem",
		justifyContent : "space-between",
		alignItems     : "flex-start"
	},
	cardListImageCtn: {
		display        : "flex",
		gap            : "1rem",
		fontSize       : "1.5rem",
		justifyContent : "space-between",
		alignItems     : "flex-start"
	},
	listItemAvatar: {
		height       : "80px",
		width        : "150px",
		borderRadius : "10px"
	},
	cardListItem: {
		display        : "flex",
		alignItems     : "stretch",
		justifyContent : "start",
		gap            : "1rem"
	},
	listItemTextContainer: {
		display       : "flex",
		flexDirection : "column"
	},
	streamViewBtn: {
		marginLeft: "2rem"
	},
	streamViewBtnText: {
		fontSize   : "1.4rem",
		fontWeight : "500"
	},
	listItemHeading: {
		fontWeight    : "500",
		fontSize      : "1.8",
		paddingBottom : "1rem"
	},
	listItemGameDiv: {
		border       : "1px solid #e7e7e7 !important",
		padding      : ".7rem",
		borderRadius : "10px",
		display      : "flex",
		gap          : ".8rem",
		alignItems   : "center"
	},
	listItemText: {
		fontWeight: "500"
	},
	gameThumbnail: {
		borderRadius : "5px",
		width        : "20px"
	},
	closeIcon: {
		width  : "30px",
		cursor : "pointer"
	}
} );