import { atom, useAtomValue, useSetAtom } from "jotai";

export const initialData = {
	id         : "",
	endTimeUTC : "",
	isLive     : false
};

export const sessionData = atom ( null );

export const useSetUpdateSession = () => {
	return useSetAtom ( sessionData );
};

export const useGetUpdateSession = () => {
	return useAtomValue ( sessionData );
};
