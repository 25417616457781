
import { capitalize } from "lodash";
import { useState }   from "react";

import {
	uploadingStates,
	useGetCancelUploadFunction,
	useGetInProgressVideoDetails,
	useGetUploadTypeAtom,
	useSetInProgressVideoDetails,
	useSetIsUploadingVideo
} from "_common/hooks/uploadVideo";
import toast            from "_common/toast";
import {
	abortDirectUpload,
	abortMultipartUpload
} from "_services/api/upload";

import Presentation from "./presentation";

const component = ( {
	isOpen,
	onClose
} ) => {

	const [ loading, setLoading ]   = useState ( false );
	const inProgressVideoDetails    = useGetInProgressVideoDetails ();
	const setInProgressVideoDetails = useSetInProgressVideoDetails ();
	const setIsUploadingVideo       = useSetIsUploadingVideo ();
	const cancelUploadFunction      = useGetCancelUploadFunction ();
	const uploadType                = useGetUploadTypeAtom ();

	const onCancelUpload = async () => {
		try {
			if ( !inProgressVideoDetails )
				throw ( {
					displayMsg: "No video upload in progress!"
				} );

			if ( cancelUploadFunction?.fn )
				cancelUploadFunction.fn ( `${ uploadType } uploading has been cancelled.` );

			if ( inProgressVideoDetails.type === "direct" ) {
				const type = inProgressVideoDetails.duration < 60 ? "reel" : "video";

				await abortDirectUpload ( { type, feedId: inProgressVideoDetails.feedId } );
			} else {
				await abortMultipartUpload ( { feedId: inProgressVideoDetails?.feedId } );
			}

			setInProgressVideoDetails ( prev => ( { ...prev, status: "ABORTED" } ) );
			setIsUploadingVideo ( uploadingStates.UPLOAD_CANCELLED );
			toast.info ( `${ capitalize ( uploadType ) } Upload Cancelled` );
		} catch ( error ) {
			toast.error ( error?.displayMsg || "Something went wrong." );
			setIsUploadingVideo ( uploadingStates.UPLOAD_PENDING );
		}
	};

	const onCancel = async () => {
		setLoading ( true );
		await onCancelUpload ();
		setLoading ( false );
		onClose ();
	};

	return (
		<Presentation
			isOpen   = { isOpen }
			loading  = { loading }
			onCancel = { onCancel }
			onClose  = { onClose }
		/>
	);
};

export default component;