import { isArray } from "lodash";

import XHR         from "_common/xhr";
import { configs } from "_config/index";

export const directVideoUpload = async ( { config, blob, uploadURLData } ) => {
	await XHR.put ( {
		url  : uploadURLData.s3PresignedUrl,
		data : blob,
		config
	}, { emptyResponse: true, noHeaders: true } );
};

export const directUploadComplete = async ( { feedId, type = "video" } ) => {
	await XHR.put ( {
		url  : `${ configs.CONTENT_UPLOAD }${ type }/direct`,
		data : { feedId: feedId }
	}, { emptyResponse: true } );
};

export const initDirectVideoUpload = async ( { videoData, type = "video" } ) => {
	const response = await XHR.post ( {
		url  : `${ configs.CONTENT_UPLOAD }${ type }/direct`,
		data : videoData
	} );

	return response;
};

export const getDirectUploadRefreshURL = async ( { feedId, type = "video" } ) => {
	const response = await XHR.post ( {
		url  : `${ configs.CONTENT_UPLOAD }${ type }/direct/refresh-presigned-url`,
		data : { feedId }
	} );

	return response.uploadData || [];
};

export const abortDirectUpload = async ( { type, feedId } ) => {
	await XHR.del ( {
		url  : `${ configs.CONTENT_UPLOAD }${ type }/direct`,
		data : { feedId }
	}, { emptyResponse: true } );

	return true;
};

export const uploadChunk = async ( { formData, config, url } ) => {
	const response = await XHR.put ( {
		url     : url,
		data    : formData,
		timeout : 24 * 60 * 60 * 1000,
		config
	}, {
		noHeaders: true
	} );

	return response;
};

export const initMultipartUpload = async ( { videoData } ) => {
	const response = await XHR.post ( {
		url  : `${ configs.CONTENT_UPLOAD }video/multipart`,
		data : videoData
	} );

	return response;
};

export const getMultipartRefreshURL = async ( { feedId, partNumberList = [] } ) => {
	const response = await XHR.post ( {
		url  : `${ configs.CONTENT_UPLOAD }video/multipart/refresh-presigned-url`,
		data : { feedId, partNumber: partNumberList }
	} );

	return response.uploadData || [];
};

export async function checkInProcessingUpload ( { contentType } ) {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.CONTENT_UPLOAD }${ contentType }/processing/v2`
	} );

	if ( isArray ( response ) && response.length )
		return response[0];

	return null;
}

export async function updateUserViewStatus ( { contentType, feedId, userViewStatus = true } ) {
	const response = await XHR.post.bind ( this ) ( {
		url  : `${ configs.CONTENT_UPLOAD }${ contentType }/processing/user-view-status`,
		data : { feedId, userViewStatus }
	} );

	if ( isArray ( response ) && response.length )
		return response[0];

	return null;
}

export async function checkInProgressUpload () {
	const response = await XHR.get ( {
		url: `${ configs.CONTENT_UPLOAD }video`
	} );

	if ( isArray ( response ) && response.length )
		return response[0];

	return null;
}

export async function checkInProgressUploadReel () {
	const response = await XHR.get ( {
		url: `${ configs.CONTENT_UPLOAD }reel`
	} );

	if ( isArray ( response ) && response.length )
		return response[0];

	return null;
}

export const getDirectUploadDetails = async ( { type = "video", feedId } ) => {
	const response = await XHR.get ( {
		url: `${ configs.CONTENT_UPLOAD }${ type }/direct/${ feedId }`
	} );

	return { ...response, type: type };
};

export const getUploadDetails = async ( { type, feedId } ) => {
	const response = await XHR.get ( {
		url: `${ configs.CONTENT_UPLOAD }video/${ type }/${ feedId }`
	} );

	return { ...response, type: type };
};

export const getUploadDetailsReel = async ( { feedId } ) => {
	const response = await XHR.get ( {
		url: `${ configs.CONTENT_UPLOAD }reel/direct/${ feedId }`
	} );

	return { ...response, type: "direct" };
};

export const completeMultipartUpload = async ( { type, feedId } ) => {
	await XHR.put ( {
		url  : `${ configs.CONTENT_UPLOAD }video/${ type }`,
		data : { feedId }
	}, { emptyResponse: true } );

	return true;
};

export const abortMultipartUpload = async ( { feedId } ) => {
	await XHR.del ( {
		url  : `${ configs.CONTENT_UPLOAD }video/multipart`,
		data : { feedId }
	}, { emptyResponse: true } );

	return true;
};