import { Box, Progress } from "@mantine/core";

import useStyles from "_styles/atoms/linearProgress";

const component = ( { color, progress, variant } ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<Box sx = { classes.root }>
			<Progress
				animate = { variant === "indeterminate" }
				// color = { color }
				radius  = { "md" }
				size    = { "lg" }
				striped = { variant === "indeterminate" }
				value   = { variant === "indeterminate" ? 100 : progress }
			/>
		</Box>
	);
};

export default component;
