import { useEffect, useState } from "react";

import toast                                                          from "_common/toast";
import { delayedFunction }                                            from "_common/utils";
import { useGetStreamHealthCheckModal, useSetStreamHealthCheckModal } from "_components/pages/studio/states";
import { checkStreamHealth }                                          from "_services/api/livestream";

import Presentation from "./presentation";

const component = ( { ivsChannelId, onSessionVerify } ) => {
	const isStreamHealthCheckModalOpen = useGetStreamHealthCheckModal ();
	const setStreamHealthCheckModal    = useSetStreamHealthCheckModal ();

	const [ state, setState ] = useState ( {
		loading     : false,
		alert       : false,
		success     : false,
		description : ""
	} );

	function updateState ( { ...args } ) {
		setState ( p => ( { ...p, ...args } ) );
	}

	// if user is not pushing then you get
	// { "state": "OFFLINE" }

	// if everything is fine then you get
	// { "state": "OK" }

	// if there any issue then you get "state: ALERT", description changes based on the error,
	// { "state": "ALERT", "description": "Please ensure that the video bitrate falls within the range of 2500kbps to 8000kbps."}

	async function handleCheckStreamHealth () {
		try {
			updateState ( { loading: true } );

			const res = await checkStreamHealth ( ivsChannelId );

			handleStreamStatus ( res );
		} catch ( error ) {
			toast.error ( error.displayMsg );

			setStreamHealthCheckModal ( false );
		} finally {
			updateState ( { loading: false } );
		}
	}

	function handleStreamStatus ( res ) {
		switch ( res.state ) {
			case "OK": {
				updateState ( { success: true } );
				onSessionVerify ();
				setTimeout ( () => {
					setStreamHealthCheckModal ( false );
				}, 2000 );
			}
				break;

			case "OFFLINE": {
				updateState ( { alert: true, description: res?.description || "" } );
			}
				break;

			case "ALERT": {
				updateState ( { alert: true, description: res?.description || "" } );
			}
				break;

			default:
				break;
		}

	}

	async function handleRetry () {
		// added a loading screen on clicking retry
		updateState ( { loading: true } );

		// waiting for 5s before retrying the api again
		await delayedFunction ( { seconds: 5 } );

		await handleCheckStreamHealth ();
	}

	useEffect ( () => {
		if ( isStreamHealthCheckModalOpen ) {
			handleCheckStreamHealth ();
		}
	}, [ isStreamHealthCheckModalOpen ] );

	return (
		<Presentation
			handleRetry             = { handleRetry }
			state                   = { state }
		/>
	);
};

export default component;