
import { Modal }           from "@mantine/core";
import React, { Fragment } from "react";

import { PrimaryButton } from "_components/atoms/button";
import FetchMore         from "_components/atoms/fetchMoreLoader";
import Icon              from "_components/atoms/icon";
import Image             from "_components/atoms/image";
import Typography        from "_components/atoms/typography";

import useStyles from "./styles";

export default function MultipleStreamModal ( props ) {
	const {
		handleModalClose,
		modalOpen,
		onGoLive,
		streams,
		onStreamSelect,
		isFetching,
		isDepleted,
		fetchStreams,
		onCreateNew,
		darkMode
	} = props;

	const { classes } = useStyles ( undefined, { name: "styles" } );

	const newStreamHandler = () => {
		onGoLive ();
		onCreateNew ();
		handleModalClose ();
	};

	return (
		<div>
			<Modal centered
				aria-describedby = "alert-dialog-description"
				aria-labelledby  = "alert-dialog-title"
				className        = { classes.dialogRoot }
				maxWidth         = "md"
				onClose          = { handleModalClose }
				opened           = { modalOpen }
				PaperProps       = { {
					style: {
						width        : "50vw",
						borderRadius : "10px"
					}
				} }
				radius           = "xl"
				size             = { "xl" }
				withCloseButton  = { false }
			>
				{/* <DialogTitle className = { classes.dialogTitle }
					id = "alert-dialog-title"
				>
					<Typography className = { classes.modalHeader }
						title = { "Current Live Streams" }
					>
					</Typography>

					<Icon className = { classes.closeIcon }
						name = "crossIcon"
						onClick = { handleModalClose }
					/>
				</DialogTitle> */}

				{/* <List sx = { { borderBottom: "1px solid #e7e7e7", height: "45vh", overflowY: "auto" } }>
					{
						streams.map ( ( card, index ) => (
							<Fragment key = { card.id }>
								<ListItem className = { classes.cardListRoot }>
									<div className = { classes.cardListImageCtn }>
										<ListItemAvatar>
											<Image alt = "multiple-stream-thumbnail-img"
												className = { classes.listItemAvatar }
												src = { card.image }
											/>
										</ListItemAvatar>

										<div className = { classes.listItemTextContainer }>
											<Typography
												className = { classes.listItemHeading }
												title = { card.name }
											/>

											<ListItemText primary = { (
												<div className = { classes.cardListItem }>

													<div className = { classes.listItemGameDiv }>

														<Typography
															color = "text.primary"
															component = "span"
															sx = { { display: "inline" } }
															title = { card.locale }
															variant = "body2"
														/>
													</div>
												</div>
											) }
											/>
										</div>
									</div>

									<PrimaryButton
										className = { classes.streamViewBtn }
										onClick = { () => onStreamSelect ( card ) }
										title = "View"
									/>

								</ListItem>

<FetchMore
isDepleted = { isDepleted}
isLoading = { isFetching}
fetchMore = { fetchStreams}
/>
							</Fragment>
						) )
					}

				</List> */}

				<PrimaryButton
					className = { classes.modalFooter }
					onClick   = { newStreamHandler }
					title     = "+ Create New Stream"

				/>

			</Modal>
		</div>
	);
}
