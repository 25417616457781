
import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {
	userCoinsContainer: {
		display      : "flex",
		alignItems   : "center",
		background   : theme.other.paletteNew.mainGradient,
		position     : "relative",
		borderRadius : "20px",
		padding      : "0.1rem 1.25rem"
		// border       : "0.5px solid rgba(255, 255, 255, 0.3)",
	},
	coinIconContainer: {
		position     : "absolute",
		right        : "-8px",
		lineHeight   : "0",
		// border       : "4px solid #fff",
		borderRadius : "50%"
	},
	icon: {
		...theme.other.svgIcons.size25
	}
} ) );
