import Presentation from "./presentation";

const AppearanceMenu = ( {
	onThemeChange,
	isDeviceTheme,
	darkMode,
	hideBackBtn,
	onClickBack
} ) => {
	return (
		<Presentation
			darkMode      = { darkMode }
			hideBackBtn   = { hideBackBtn }
			isDeviceTheme = { isDeviceTheme }
			onClickBack   = { onClickBack }
			onThemeChange = { onThemeChange }
		/>
	);
};

export default AppearanceMenu;
