import { Divider } from "@mantine/core";

import useStyles from "_styles/atoms/divider";

const component = ( { className, style, ...otherProps } ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<Divider
			className = { `${ classes.container } ${ className }` }
			size      = "sm"
			style     = { style }
		/>
	);
};

export default component;
