import { useEffect } from "react";

import {
	uploadingStates,
	useGetInProgressVideoDetails,
	useGetIsUploadingVideo,
	useGetUploadNotification,
	useSetInProcessingItem,
	useSetInProgressVideoDetails,
	useSetUploadTypeAtom
} from "_common/hooks/uploadVideo";
import {
	checkInProcessingUpload,
	checkInProgressUpload,
	checkInProgressUploadReel,
	getUploadDetails,
	getUploadDetailsReel
} from "_services/api/upload";

import Presentation from "./presentation";

const VideoUploadStatus = () => {

	const setInProgressVideoDetails = useSetInProgressVideoDetails ();
	const inProgressVideoDetails    = useGetInProgressVideoDetails ();
	const uploadNotificationConfig  = useGetUploadNotification ();
	const setUploadType             = useSetUploadTypeAtom ();
	const isUploading               = useGetIsUploadingVideo ();
	const setInProcessingItem       = useSetInProcessingItem ();

	// const { mountTransitionObj, setNotiCleared, notiCleared } = useTransitionState ( inProcessingVideoItem );

	useEffect ( () => {
		getInProgressStatus ();
		getInProcessingStatus ();
	}, [] );

	const getInProcessingStatus = async () => {
		const videoRes = await checkInProcessingUpload ( { contentType: "video" } );
		const reelRes  = await checkInProcessingUpload ( { contentType: "reel" } );

		if ( !videoRes && !reelRes ) return;

		if ( videoRes ) {
			setInProcessingItem ( p => {
				return {
					reel  : p?.reel,
					video : {
						type        : "video",
						feedId      : videoRes.feedId,
						status      : videoRes.status,
						userMessage : videoRes.userMessage
					}
				};
			} );
		}

		if ( reelRes ) {
			setInProcessingItem ( p => {
				return {
					video : p?.video,
					reel  : {
						type        : "reel",
						feedId      : reelRes.feedId,
						status      : reelRes.status,
						userMessage : reelRes.userMessage
					}
				};
			} );
		}

	};

	const getInProgressStatus = async () => {
		const videoRes = await checkInProgressUpload ();
		const reelRes  = await checkInProgressUploadReel ();

		if ( !videoRes && !reelRes ) return;

		let uploadDetailsResult;

		if ( reelRes ) {
			uploadDetailsResult = await getUploadDetailsReel ( { feedId: reelRes.feedId } );
		} else {
			uploadDetailsResult = await getUploadDetails ( { type: videoRes.type, feedId: videoRes.feedId } );
		}

		setUploadType ( uploadDetailsResult?.duration > 60 ? "videos" : "reels" );

		if ( videoRes?.type === "multipart" )
			uploadDetailsResult.parts = uploadDetailsResult.parts.map ( item => ( { ...item, presignedUrl: item.s3PresignedUrl } ) ); // format data
		setInProgressVideoDetails ( uploadDetailsResult );
	};

	// if there is no video currently in upload or currentUpload was aborted or not completed then dont show notification bar
	if (
		!inProgressVideoDetails ||
		inProgressVideoDetails?.status === "ABORTED" ||
		inProgressVideoDetails?.status !== "COMPLETED"
	) {
		return null;
	}

	// if upload is pending or upload or upload was cancelled by user then dont show notification bar
	if ( isUploading === uploadingStates.UPLOAD_PENDING ||
		isUploading === uploadingStates.UPLOAD_CANCELLED ) {
		return null;
	}

	return (
		<Presentation
			successStatusObj   = { uploadNotificationConfig }
		/>
	);
};

export default VideoUploadStatus;
