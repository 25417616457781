
export const langArray = [
	{
		name   : "हिन्दी",
		eName  : "Hindi",
		locale : "hi"
	},
	{
		name   : "English",
		eName  : "English",
		locale : "en"
	},
	{
		name  : "বাংলা",
		eName : "Bangla",

		locale: "bn"
	},
	{
		name  : "தமிழ்",
		eName : "Tamil",

		locale: "ta"
	},
	{
		name  : "తెలుగు",
		eName : "Telugu",

		locale: "te"
	},
	{
		name  : "मराठी",
		eName : "Marathi",

		locale: "mr"
	},
	{
		name  : "ಕನ್ನಡ",
		eName : "Kannada",

		locale: "kn"
	},
	{
		name  : "ਪੰਜਾਬੀ",
		eName : "Punjabi",

		locale: "pa"
	},
	{
		name  : "ગુજરાતી",
		eName : "Gujarati",

		locale: "gu"
	},
	{
		name  : "മലയാളം",
		eName : "Malayalam",

		locale: "ml"
	},
	{
		name   : "Indonesia",
		eName  : "Bahasa Indonesia",
		locale : "id"
	}

];

export const localeAbbrev = {
	en : "English",
	hi : "Hindi",
	bn : "Bangla",
	ta : "Tamil",
	te : "Telugu",
	mr : "Marathi",
	kn : "Kannada",
	pa : "Punjabi",
	gu : "Gujarati",
	ml : "Malayalam",
	id : "Bahasa Indonesia"
};
