import { createStyles } from "@mantine/emotion";

import { palette } from "_styles/theme";

export default createStyles ( theme => ( {
	themeMenuContainer: {
		margin: "1rem 0"
	},
	headerWrapper: {
		display    : "flex",
		alignItems : "center",
		padding    : "1rem 0 !important"
	},
	header: {
		display    : "flex",
		alignItems : "center"
	},
	themeMenuItem: {
		borderRadius   : "0",
		display        : "flex",
		justifyContent : "space-between",
		width          : "20rem",
		padding        : "1rem !important",
		"& p"          : {
			color: theme.other.paletteNew.secondaryDarkGrey
		},
		"&[data-hovered]": {
			backgroundColor: theme.other.darkMode ?
				theme.other.paletteNew.darkModeDarkGrey80 :
				theme.other.paletteNew.primaryBackgroundGrey
		}
	},
	activeMenu: {
		backgroundColor : theme.other.paletteNew.darkModeDarkBlue50,
		"& p"           : {
			color: theme.other.colors.text.primary
		},

		"&[data-hovered]": {
			backgroundColor: theme.other.paletteNew.darkModeDarkBlue50
		}
	},
	backIcon: {
		...theme.other.svgIcons.size20,
		marginLeft : "1rem",
		cursor     : "pointer"
	},
	tickIcon: {
		...theme.other.svgIcons.size20
	}
} ) );
