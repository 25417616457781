import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		body: {
			background   : theme.other.colors.dialog.primary,
			borderRadius : "1rem",
			overflow     : "hidden",
			padding      : "2rem"
		},
		root: {
			position: "relative"
		},
		content: {
			borderRadius : "1rem",
			background   : theme.other.colors.dialog.primary
		},
		closeIcon: {
			height     : "3rem",
			width      : "3rem",
			display    : "flex",
			marginLeft : "auto",
			position   : "absolute",
			top        : "2rem",
			right      : "2rem",
			cursor     : "pointer"
		},
		errorAlertIcon: {
			height       : "5rem",
			width        : "5rem",
			marginBottom : "1.4rem"

		},
		mainBody: {
			textAlign : "center",
			marginTop : "2rem"
		},
		title: {
			fontWeight : "500",
			fontSize   : "1.8rem"
		},
		subTitle: {
			fontSize : "1.4rem",
			margin   : "1.6rem 0"
		},
		buttonDiv: {
			display       : "flex",
			flexDirection : "column",
			alignItems    : "center",
			gap           : "1rem"
		},
		newVideoBtn: {
			color  : theme.other.paletteNew.primaryDarkBlue,
			cursor : "pointer"
		}

	};
} );
